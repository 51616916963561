<template>
  <a-modal v-model:visible="selectGoodsModalVisible"
           title="选择商品"
           width="400px"
           :bodyStyle="{paddingBottom: 0}"
           :confirmLoading="loading"
           @ok="submitGoodsSelected"
           @cancel="$emit('update:visible', false)">

    <a-input-search v-model:value="searchGoodsForm.title"
                    class="mb-8"
                    placeholder="请输入商品名称"
                    @change="goods.loading = true; searchGoodsByTitle()" />

    <ul ref="goodsListRef"
        class="overflow-y-auto"
        style="height: 240px"
        @scroll="loadMoreGoods">
      <div v-for="goodsItem in goods.list"
           :key="goodsItem.id"
           class="flex justify-between items-center py-8 px-16 text-color-333 cursor-pointer hover:bg-blue-1"
           :class="{'bg-blue-1': selectedIdList.includes(goodsItem.id)}"
           @click="handleSelectSingleGoods(goodsItem)">
        <li v-html="highlight(goodsItem.title, searchGoodsForm.title)"
            class="truncate"
            style="width: 280px;">
        </li>
        <svg-icon v-show="selectedIdList.includes(goodsItem.id)"
                  class="text-admin-primary"
                  type="iconxuanzhong" />
      </div>
      <a-spin v-if="goods.loading"
              class="block text-center" />
    </ul>
  </a-modal>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  watch,
  onMounted,
  computed,
} from "vue";
import _ from "lodash";

import SvgIcon from "@/components/SvgIcon";

import goodsApi from "@/service/api/goods";

export default defineComponent({
  name: "SelectScrmGoodsModal",

  props: {
    visible: Boolean,
    selectedGoods: Array,
    loading: { type: Boolean, default: false },
  },

  emits: ["update:visible", "confirm"],

  components: {
    SvgIcon,
  },

  setup(props, { emit }) {
    const selectGoodsModalVisible = ref(false);

    watch(
      () => props.visible,
      (newVisible) => {
        selectGoodsModalVisible.value = newVisible;
      }
    );
    const goodsList = ref([]);

    watch(
      () => [...props.selectedGoods],
      () => {
        goodsList.value = _.cloneDeep(props.selectedGoods);
      },
      { immediate: true }
    );

    const searchGoodsForm = reactive({
      title: "",
      status: "up",
      page: 0,
    });

    const goods = reactive({
      list: [],
      loading: false,
      finished: false,
    });

    const goodsListRef = ref();

    const searchGoodsByTitle = _.debounce(() => {
      _.assign(goods, {
        list: [],
        loading: false,
        finished: false,
      });

      searchGoodsForm.page = 0;

      getGoods();
    }, 500);

    const loadMoreGoods = _.debounce(() => {
      if (goods.finished) {
        return;
      }

      const { scrollHeight, scrollTop, clientHeight } = goodsListRef.value;
      if (scrollHeight - scrollTop - clientHeight > 30) {
        return;
      }

      getGoods();
    }, 500);

    async function getGoods() {
      goods.loading = true;

      const res = await goodsApi.search(searchGoodsForm);
      searchGoodsForm.page++;

      goods.list = _.concat(goods.list, res.data);
      goods.loading = false;

      goods.finished = goods.list.length >= res.total;
    }

    const highlight = (text, keyword) => {
      return _.replace(
        text,
        keyword,
        `<span class="keyword">${keyword}</span>`
      );
    };

    const selectedIdList = computed(() => {
      const ids = [];
      if (goodsList.value.length) {
        _.each(goodsList.value, (item) => ids.push(item.id));
      }
      return ids;
    });

    const handleSelectSingleGoods = (goodsItem) => {
      if (_.includes(selectedIdList.value, goodsItem.id)) {
        _.remove(goodsList.value, (item) => {
          return item.id === goodsItem.id;
        });
      } else {
        goodsList.value.push(goodsItem);
      }
    };

    const submitGoodsSelected = () => {
      emit("confirm", goodsList.value);
    };

    onMounted(() => {
      getGoods();
    });

    return {
      selectGoodsModalVisible,

      searchGoodsForm,
      goods,
      goodsListRef,
      searchGoodsByTitle,
      getGoods,
      loadMoreGoods,

      selectedIdList,

      highlight,

      goodsList,
      handleSelectSingleGoods,
      submitGoodsSelected,
    };
  },
});
</script>
<style lang='less' scoped>
ul,
li {
  list-style: none;
  padding: 0;
}

:deep(.keyword) {
  color: @blue-5;
}
</style>